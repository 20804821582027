import { z } from 'zod'
import { Language } from '@/api/useLanguages'
import { roleSchema } from './useRoles.types'

export const userSchema = z.strictObject({
  id: z.number(),
  companyId: z.number(),
  email: z.string(),
  firstName: z.string(),
  lastName: z.string(),
  jobTitle: z.string().nullable(),
  language: z.nativeEnum(Language),
  locationId: z.number().nullable(),
  emailNotifications: z.boolean(),
  useLightTheme: z.boolean(),
  insightsColumns: z.array(z.string()).nullable(),
  isDeleted: z.boolean(),
  roles: z.array(roleSchema),
})

export type User = z.infer<typeof userSchema>

export const userMutationDataSchema = userSchema
  .pick({
    id: true,
    email: true,
    firstName: true,
    lastName: true,
    jobTitle: true,
    language: true,
    locationId: true,
    emailNotifications: true,
    useLightTheme: true,
    insightsColumns: true,
  })
  .extend({
    rolesAttributes: z.array(roleSchema.partial()),
  })
  .partial()
  .strip()

export type UserMutationData = z.infer<typeof userMutationDataSchema>

export interface UserFilterParams {
  includeDeleted?: boolean
}
